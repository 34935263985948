// SiteListerPage.tsx
import React, { useState, useEffect, useContext } from 'react';
import { doc, setDoc, getDoc, getDocs, collection, updateDoc, deleteDoc } from 'firebase/firestore';
import { dbHrefsets } from '../../App';
import { UserContext } from '../../universal/context/UserContext';
import { useNavigate } from 'react-router-dom';

import { useLoader } from '../../universal/context/LoaderContext';
import { useNotification } from '../../universal/context/NotificationContext';

interface ISite {
  siteId: string;
  siteName: string;
  siteURL: string;
  status: boolean;
  category: string;
}

const SiteListerPage = () => {
  const { showNotification } = useNotification();
  const { showLoader, hideLoader, updateProgress } = useLoader();

  const [showMessage, setShowMessage] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [siteId, setSiteId] = useState<string>('');
  const [siteName, setSiteName] = useState<string>('');
  const [siteURL, setSiteURL] = useState<string>('');
  const [siteCategory, setSiteCategory] = useState<string>('');
  const [siteList, setSiteList] = useState<ISite[]>([]);
  const [filteredSiteList, setFilteredSiteList] = useState<ISite[]>([]);
  const [categories, setCategories] = useState<{ id: string; name: string }[]>([]);
  const [categoryFilter, setCategoryFilter] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [levelThreeLoading, setLevelThreeLoading] = useState<boolean>(false);
  const [showNewCategoryPopup, setShowNewCategoryPopup] = useState<boolean>(false);
  const [newCategoryName, setNewCategoryName] = useState<string>('');
  const [editingSite, setEditingSite] = useState<ISite | null>(null);
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [siteToDelete, setSiteToDelete] = useState<ISite | null>(null);
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
  //Check auths
  if (!user?.allowHreflangSetEditing) {
    navigate('/');
  }
});
  
useEffect(() => {
    fetchSites();
    fetchCategories();
  }, []);

  useEffect(() => {
    applyFilters(searchQuery, categoryFilter);
  }, [siteList, searchQuery, categoryFilter]);

  const isValidSiteData = (data: any): data is ISite => {
    return data && typeof data.siteId === 'string' && typeof data.siteName === 'string' && typeof data.siteURL === 'string';
  };

  const fetchSites = async () => {
    showLoader("Pinging sites...");
    const sitesCollectionRef = collection(dbHrefsets, 'sites');
    try {
      const querySnapshot = await getDocs(sitesCollectionRef);
      const sites = querySnapshot.docs.map((doc) => doc.data()).filter(isValidSiteData);
  
      const siteCount = sites.length;
      const batchSize = 5;  // Define a batch size for parallel processing
      const batches = [];
  
      for (let i = 0; i < sites.length; i += batchSize) {
        const batch = sites.slice(i, i + batchSize).map(async (data) => {
          const status = await pingSite(data.siteURL);
          return {
            siteId: data.siteId,
            siteName: data.siteName,
            siteURL: data.siteURL,
            status,
            category: data.category || 'Uncategorized'
          };
        });
        batches.push(batch);
      }
  
      const fetchedSites = [];
      for (let batchIndex = 0; batchIndex < batches.length; batchIndex++) {
        const batch = batches[batchIndex];
        showLoader(`Pinging batch ${batchIndex + 1}/${batches.length}...`);
        const batchResults = await Promise.all(batch);
        fetchedSites.push(...batchResults);
        updateProgress(((batchIndex + 1) / batches.length) * 100);
      }
  
      setSiteList(fetchedSites);
      showNotification('Sites fetched successfully!', 'success');
    } catch (error) {
      console.error("Error fetching sites:", error);
      showNotification('Error fetching sites!', 'error');
    } finally {
      hideLoader();
    }
  };
  
  

  const fetchCategories = async () => {
    const categoriesCollectionRef = collection(dbHrefsets, 'categories');
    try {
      const querySnapshot = await getDocs(categoriesCollectionRef);
      const fetchedCategories = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data() as { name: string }
      }));
      setCategories(fetchedCategories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const pingSite = async (siteURL: string) => {
    const token = process.env.REACT_APP_BEARER_TOKEN;
    try {
      const response = await fetch(`${siteURL}wp-json/myplugin/v1/ping`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.ok;
    } catch (error) {
      console.error("Error pinging site:", error);
      return false;
    }
  };

  const createSite = async () => {
    showLoader();

    if (!siteId) {
      showNotification('Site ID field is empty!', 'error');
      hideLoader();
      return;
    }
    if (!siteName) {
      showNotification('Site name field is empty!', 'error');
      hideLoader();
      return;
    }
    if (!siteURL) {
      showNotification('Site URL field is empty!', 'error');
      hideLoader();
      return;
    }
    if (!siteCategory) {
      showNotification('Site category field is empty!', 'error');
      hideLoader();
      return;
    }

    const sitesRef = doc(dbHrefsets, 'sites', siteId);

    try {
      const docSnapshot = await getDoc(sitesRef);
      if (docSnapshot.exists()) {
        showNotification('A site with this ID already exists!', 'error');
      } else {
        await setDoc(sitesRef, { siteId, siteName, siteURL, category: siteCategory });
        setSiteId('');
        setSiteName('');
        setSiteURL('');
        setSiteCategory('');
        showNotification('Site added successfully!', 'success');
      }
    } catch (error) {
      showNotification('Failed to create or check site!', 'error');
    }
    hideLoader();
  };

  const updateSite = async () => {
    if (!editingSite) return;
    showLoader();

    const sitesRef = doc(dbHrefsets, 'sites', editingSite.siteId);

    try {
      await updateDoc(sitesRef, {
        siteName: siteName,
        siteURL: siteURL,
        category: siteCategory
      });
      showNotification('Site updated successfully!', 'success');
      fetchSites();
      setEditingSite(null);
      setSiteId('');
      setSiteName('');
      setSiteURL('');
      setSiteCategory('');
      setFormVisible(false);
    } catch (error) {
      showNotification('Failed to update site!', 'error');
    }
    hideLoader();
  };

  const confirmDeleteSite = (site: ISite) => {
    setSiteToDelete(site);
    setShowDeletePopup(true);
  };

  const deleteSite = async () => {
    if (!siteToDelete) return;
    showLoader();
    const sitesRef = doc(dbHrefsets, 'sites', siteToDelete.siteId);

    try {
      await deleteDoc(sitesRef);
      showNotification('Site deleted successfully!', 'success');
      fetchSites();
    } catch (error) {
      showNotification('Error deleting site!', 'error');
    }
    setShowDeletePopup(false);
    setSiteToDelete(null);
    hideLoader();
  };

  const hideMessage = () => {
    setShowMessage(false);
  };

  const handleFilter = (query: string) => {
    setSearchQuery(query);
    applyFilters(query, categoryFilter);
  };

  const handleCategoryFilterChange = (category: string) => {
    setCategoryFilter(category);
    applyFilters(searchQuery, category);
  };

  const handleShowNewCategoryPopup = () => {
    setShowNewCategoryPopup(true);
  };

  const handleHideNewCategoryPopup = () => {
    setShowNewCategoryPopup(false);
    setNewCategoryName('');
  };

  const handleAddCategory = async () => {
    if (!newCategoryName.trim()) {
      showNotification('Category name cannot be empty!', 'error');
      return;
    }

    setLevelThreeLoading(true);
    const newCategoryRef = doc(dbHrefsets, 'categories', newCategoryName.trim());

    try {
      const docSnapshot = await getDoc(newCategoryRef);
      if (docSnapshot.exists()) {
        setNewCategoryName('');
        showNotification('Category ' + newCategoryName.trim() + ' already exists!', 'error');
      } else {
        await setDoc(newCategoryRef, { name: newCategoryName.trim() });
        setCategories([...categories, { id: newCategoryName.trim(), name: newCategoryName.trim() }]);
        setNewCategoryName('');
        setShowNewCategoryPopup(false);
        showNotification('Category ' + newCategoryName.trim() + ' added successfully!', 'error');
      }
    } catch (error) {
      showNotification('Error adding category: ' + error, 'error');
    }

    setLevelThreeLoading(false);
  };

  const applyFilters = (query: string, category: string) => {
    let filtered = siteList;

    if (query.trim() !== '') {
      filtered = filtered.filter(
        (site) =>
          site.siteName.toLowerCase().includes(query.toLowerCase()) ||
          site.siteURL.toLowerCase().includes(query.toLowerCase())
      );
    }

    if (category) {
      filtered = filtered.filter((site) => site.category === category);
    }

    setFilteredSiteList(filtered);
  };

  const groupByCategory = (sites: ISite[]) => {
    return sites.reduce((groups: { [key: string]: ISite[] }, site: ISite) => {
      const category = site.category || 'Uncategorized';
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(site);
      return groups;
    }, {});
  };

  const groupedSites = groupByCategory(filteredSiteList);

  const handleEditSite = (site: ISite) => {
    setEditingSite(site);
    setSiteId(site.siteId);
    setSiteName(site.siteName);
    setSiteURL(site.siteURL);
    setSiteCategory(site.category);
    setFormVisible(true);
  };

  const handleCancelEdit = () => {
    setEditingSite(null);
    setSiteId('');
    setSiteName('');
    setSiteURL('');
    setSiteCategory('');
    setFormVisible(false);
  };

  return (
    <div className="dashpanel">
    <div className="main">
      <div className="head">
        {showMessage && (
          <div className="popup-overlay">
            <div className="delete-confirmation-popup">
              <p><b>{popupMessage}</b></p>
              <button className='sitemanagerbutton selected' onClick={() => hideMessage()}>OKAY</button>
            </div>
          </div>
        )}
        {showNewCategoryPopup && (
          <div className="edit-overlay z-ind-25">
            <div className="delete-confirmation-popup">
              <div className="add-client-form edit-hreflang">
                {levelThreeLoading && <div className="loader levelTwo"></div>}
                {!levelThreeLoading && (
                  <>
                    <h3>Add New Category</h3>
                    <label>Category Name</label>
                    <input
                      type="text"
                      value={newCategoryName}
                      onChange={(e) => setNewCategoryName(e.target.value)}
                      placeholder="New Category Name"
                    />
                    <div className='buttonsetcontainer hrefpopup'>
                      <button style={{ width: 150 }} className='sitemanagerbutton addhref chunkpadded' onClick={handleAddCategory}>Add Category</button>
                      <button style={{ width: 150 }} className='sitemanagerbutton deletehref chunkpadded' onClick={handleHideNewCategoryPopup}>Cancel</button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {showDeletePopup && siteToDelete && (
          <div className="popup-overlay">
            <div className="delete-confirmation-popup">
              <p>Are you sure you want to delete the site link to <b style={{color:'red'}}>{siteToDelete.siteName}</b>?</p>
              <p>You cannot undo this action.</p>
              <div className='buttonsetcontainer'>
                <button className='sitemanagerbutton deletehref minor' onClick={() => deleteSite()}>DELETE SITE</button>
                <button className='sitemanagerbutton' onClick={() => setShowDeletePopup(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}
        <h1>Site List</h1>
        <h2>Here you can view <b className="usertext">Active Sites</b> and their status.</h2>
        <p style={{ marginBottom: 10 }}>Add all the sites used in the hreflangs so they can communicate.</p>
            <div className="add-client-form">
              <h3>{editingSite ? 'Edit Site' : 'Add new Site'}</h3>
              <label>Site ID Code (GEO)</label>
              <input type="text" value={siteId} onChange={(e) => setSiteId(e.target.value)} placeholder="zms-nz" disabled={!!editingSite} />
              <label>Site Name</label>
              <input type="text" value={siteName} onChange={(e) => setSiteName(e.target.value)} placeholder="Zamsino New Zealand" />
              <label>Site URL</label>
              <input type="text" value={siteURL} onChange={(e) => setSiteURL(e.target.value)} placeholder="https://zamsino.com/nz/" />
              <label>Category</label>
              <div className='flatcat'>
                <select
                  value={siteCategory}
                  onChange={(e) => setSiteCategory(e.target.value)}
                >
                  <option value="">Select a category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <div style={{ marginBottom: 10 }} className='plus' onClick={handleShowNewCategoryPopup}>+</div>
              </div>
              <button className='sitemanagerbutton empty' onClick={editingSite ? updateSite : createSite}>
                {editingSite ? 'Update Site' : 'Add New Site'}
              </button>
              {editingSite && (
                <button style={{margin:'10px 0'}} className='sitemanagerbutton deletehref' onClick={handleCancelEdit}>Cancel</button>
              )}
            </div>
            {!editingSite &&(<>
            <p style={{ marginTop: '5px' }}><b>Wordpress Plugin must be activated to ensure communication</b></p>
            <div style={{ width: 350, padding: 5, display: 'flex' }} className="add-client-form edit-hreflang">
              <input style={{ marginBottom: 0 }} type="text" value={searchQuery} onChange={(e) => handleFilter(e.target.value)} placeholder="Search Sites" />
              <select style={{ marginBottom: 0, marginLeft: 5 }} value={categoryFilter} onChange={(e) => handleCategoryFilterChange(e.target.value)}>
                <option value="">All Categories</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              <div className='flatcat'>
                <div style={{ marginBottom: 0 }} className='plus' onClick={handleShowNewCategoryPopup}>+</div>
              </div>
            </div>
            <div className='catblockcontainer'>
              {Object.entries(groupedSites).map(([category, sites]: [string, ISite[]]) => (
                <div className='catblock' key={category}>
                  <h2 className='categorytitle'>{category}</h2>
                  <div className='bubble-container'>
                    {sites.map((site: ISite) => (
                      <div key={site.siteId} className='bubble hrefsets'>
                        <h2 style={{ fontSize: '12px' }}>{site.siteName}</h2>
                        <h3 style={{ fontSize: '10px' }}>({site.siteId})</h3>
                        <h3>{site.siteURL}</h3>
                        {site.status ? (
                          <div className="flex-disp"><div className="circle pulse green"></div><span style={{ color: 'green', fontWeight: 'bold' }}>Online</span></div>
                        ) : (
                          <div className="flex-disp"><div className="circle antipulse denied"></div><span className='wiggle-text' style={{ color: 'red', fontWeight: 'bold' }}>Offline</span></div>
                        )}
                        <div className='buttonsetcontainer'>
                          <button className='sitemanagerbutton selectedhref' onClick={() => handleEditSite(site)}>Edit</button>
                          <button className='sitemanagerbutton deletehref' onClick={() => confirmDeleteSite(site)}>Delete</button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            </>)}
      </div>
    </div>
    </div>
  );
};

export default SiteListerPage;
